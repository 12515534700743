body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

ul.menu {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

ul.menu li {
  background-color: #282c34;
  display: inline;
  margin: 0 10px;
  padding: 10px;
}

ul.menu li a,
ul.menu li a:visited,
ul.menu li a:active {
  color: white;
}
